import React from 'react'

export default function NotFound() {
    return (
        <>
            <h2>404</h2>
            <p>Sorry, that page doesn't exist</p>
        </>
    )
}
